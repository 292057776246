import React from 'react'
import Router from 'next/router'
import fetcher from '../helpers/fetcher'
import jwt from 'jsonwebtoken'

export default function useAuth(redirect=true) {
    function getTokenFromCookie() {
        if (typeof document !== 'undefined' && document.cookie) {
            const token = document.cookie
                .split('; ')
                .find(row => row.startsWith('authToken'))
                
            return token ? token.split('=')[1] : false
        }
        return false
    }
    const [token, setToken] = React.useState(null)

    React.useEffect(() => {
        setToken(getTokenFromCookie())
    }, [])

    React.useEffect(() => {
        if (token === false && redirect) {
            Router.replace(process.env.NEXT_PUBLIC_AUTH_APP_URL + '?callbackUrl=' + window.location.href)
        }
    }, [token])

    const logout = () => {
        document.cookie = `authToken=;expires=Thu, 01 Jan 1970 00:00:00 GMT;sameSite=strict;domain=${process.env.NEXT_PUBLIC_COOKIE_DOMAIN};path=/` 
        setToken(null)
        Router.replace(process.env.NEXT_PUBLIC_AUTH_APP_URL + '?callbackUrl=' + window.location.href)
    }

    return {
        user: jwt.decode(token),
        token,
        logout,
    }
}

export async function appleSignIn(payload, { callbackUrl = process.env.NEXT_PUBLIC_PUBLIC_APP_URL }) {
    try {
        const request = await fetcher('/api/auth/apple', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ payload }),
        })
        document.cookie = "authToken=" + request.result + `;path=/;max-age=${60*60*24*60};sameSite=strict;domain=${process.env.NEXT_PUBLIC_COOKIE_DOMAIN}` 
        Router.push(callbackUrl)
    } catch (e) {
        return Promise.reject(e)
    }
}

export async function facebookSignIn({ code }, { callbackUrl = process.env.NEXT_PUBLIC_PUBLIC_APP_URL }) {
    try {
        const request = await fetcher('/api/auth/facebook', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ code }),
        })
        document.cookie = "authToken=" + request.result + `;path=/;max-age=${60*60*24*60};sameSite=strict;domain=${process.env.NEXT_PUBLIC_COOKIE_DOMAIN}` 
        Router.push(callbackUrl)
    } catch (e) {
        return Promise.reject(e)
    }
}

export async function credentialsSignIn({ email, password }, { callbackUrl = process.env.NEXT_PUBLIC_PUBLIC_APP_URL }) {
    try {
        const request = await fetcher('/api/auth/credentials', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ email, password }),
        })
        document.cookie = "authToken=" + request.result + `;path=/;max-age=${60*60*24*60};sameSite=strict;domain=${process.env.NEXT_PUBLIC_COOKIE_DOMAIN}` 
        Router.push(callbackUrl)
    } catch (e) {
        return Promise.reject(e)
    }
}

export async function signUp(userData, { callbackUrl = process.env.NEXT_PUBLIC_PUBLIC_APP_URL }) {
    try {
        const request = await fetcher('/api/auth/signup', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(userData),
        })
        document.cookie = "authToken=" + request.result + `;path=/;max-age=${60*60*24*60};sameSite=strict;domain=${process.env.NEXT_PUBLIC_COOKIE_DOMAIN}` 
        Router.push(callbackUrl)
    } catch (e) {
        return Promise.reject(e)
    }
}
