import { useState, useMemo } from 'react'
import Logo from '@byma/shared/components/Logo'
import Head from 'next/head'
import useFilters from '@byma/shared/hooks/useFilters'
import Link from "next/link"
import { useRouter } from "next/router"
import { FiLogOut, FiBarChart2, FiHelpCircle, FiX, FiMenu, FiGrid, FiBox, FiShoppingBag, FiLayers, FiUsers } from 'react-icons/fi'
const bottomMenuItems = [
    { title: 'Equipes', key: '/teams', route: '/teams', icon: <FiUsers /> },
    { title: 'Meus contratos', key: '/contracts', route: '/contracts', icon: <FiUsers /> },
    { title: 'Ajuda', key: 'https://docs.producer.byma.com.br', route: 'https://docs.producer.byma.com.br', icon: <FiHelpCircle /> },
]

function MenuItem({active = false, item}) {
    if (!item) {
        return false
    }
    return (
        <Link href={item.route}><a>
            <button className={`flex items-center rounded-full cursor-pointer text-sm text-left hover:font-bold hover:text-primary hover:bg-red-100 ${active && 'bg-red-100 text-primary font-bold'} px-4 py-2 mb-2 focus:bg-red-200 w-full`}>
                <div className="w-5 h-5">
                    {item.icon}
                </div>
                <p className="ml-2">
                    {item.title}
                </p>
            </button>
        </a></Link>
    )
}

function BottomMenuItem({active = false, item}) {
    if (!item) {
        return false
    }
    return (
        <Link href={item.route}><a>
            <button className={`flex items-center rounded-full cursor-pointer text-sm text-gray-900 hover:font-bold hover:text-primary hover:bg-red-100 ${active && 'bg-red-100 text-primary font-bold'} px-4 py-2 mb-1 focus:bg-red-200 w-full`}>
                {item.icon}
                <p className="ml-2">
                    {item.title}
                </p>
            </button>
        </a></Link>
    )
}

function getFirstRoute(pathname) {
    if (pathname === '/') {
        return pathname
    }
    return `/${pathname.split('/')[1]}` 
}


export default function Layout({ children, session }) {
    const { logout, user } = session
    const [menuOpen, setMenuOpen] = useState(false)
    const { query, pathname } = useRouter()

    const { noSearchQueryString } = useFilters(query)

    const menuItems = useMemo(() => [
        { title: 'Dashboard', key: '/', route: `/?${noSearchQueryString}`, icon: (
            <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                <path d="M5 3a2 2 0 00-2 2v2a2 2 0 002 2h2a2 2 0 002-2V5a2 2 0 00-2-2H5zM5 11a2 2 0 00-2 2v2a2 2 0 002 2h2a2 2 0 002-2v-2a2 2 0 00-2-2H5zM11 5a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2V5zM11 13a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2v-2z" />
            </svg>
        ) },
        { title: 'Eventos', key: '/events', route: '/events', icon: (
            <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                <path fillRule="evenodd" d="M6 2a1 1 0 00-1 1v1H4a2 2 0 00-2 2v10a2 2 0 002 2h12a2 2 0 002-2V6a2 2 0 00-2-2h-1V3a1 1 0 10-2 0v1H7V3a1 1 0 00-1-1zm0 5a1 1 0 000 2h8a1 1 0 100-2H6z" clipRule="evenodd" />
            </svg>
        ) },
        { title: 'Financeiro', key: '/invoices', route: `/invoices`, icon: (
            <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                <path d="M8.433 7.418c.155-.103.346-.196.567-.267v1.698a2.305 2.305 0 01-.567-.267C8.07 8.34 8 8.114 8 8c0-.114.07-.34.433-.582zM11 12.849v-1.698c.22.071.412.164.567.267.364.243.433.468.433.582 0 .114-.07.34-.433.582a2.305 2.305 0 01-.567.267z" />
                <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm1-13a1 1 0 10-2 0v.092a4.535 4.535 0 00-1.676.662C6.602 6.234 6 7.009 6 8c0 .99.602 1.765 1.324 2.246.48.32 1.054.545 1.676.662v1.941c-.391-.127-.68-.317-.843-.504a1 1 0 10-1.51 1.31c.562.649 1.413 1.076 2.353 1.253V15a1 1 0 102 0v-.092a4.535 4.535 0 001.676-.662C13.398 13.766 14 12.991 14 12c0-.99-.602-1.765-1.324-2.246A4.535 4.535 0 0011 9.092V7.151c.391.127.68.317.843.504a1 1 0 101.511-1.31c-.563-.649-1.413-1.076-2.354-1.253V5z" clipRule="evenodd" />
            </svg>
        )},
        { title: 'Ingressos', key: '/tickets', route: `/tickets?${noSearchQueryString}`, icon: (
            <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                <path d="M2 6a2 2 0 012-2h12a2 2 0 012 2v2a2 2 0 100 4v2a2 2 0 01-2 2H4a2 2 0 01-2-2v-2a2 2 0 100-4V6z" />
            </svg>
        ) },
        { title: 'Relatório', key: '/report', route: `/report?${noSearchQueryString}`, icon: (
            <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                <path d="M2 11a1 1 0 011-1h2a1 1 0 011 1v5a1 1 0 01-1 1H3a1 1 0 01-1-1v-5zM8 7a1 1 0 011-1h2a1 1 0 011 1v9a1 1 0 01-1 1H9a1 1 0 01-1-1V7zM14 4a1 1 0 011-1h2a1 1 0 011 1v12a1 1 0 01-1 1h-2a1 1 0 01-1-1V4z" />
            </svg>
        )},
        { title: 'Vendas', key: '/orders', route: `/orders?${noSearchQueryString}`, icon: (
            <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                <path fillRule="evenodd" d="M10 2a4 4 0 00-4 4v1H5a1 1 0 00-.994.89l-1 9A1 1 0 004 18h12a1 1 0 00.994-1.11l-1-9A1 1 0 0015 7h-1V6a4 4 0 00-4-4zm2 5V6a2 2 0 10-4 0v1h4zm-6 3a1 1 0 112 0 1 1 0 01-2 0zm7-1a1 1 0 100 2 1 1 0 000-2z" clipRule="evenodd" />
            </svg>
        )},
        { title: 'Carrinhos', key: '/carts', route: `/carts?${noSearchQueryString}`, icon: (
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="w-5 h-5">
                <path d="M1 1.75A.75.75 0 011.75 1h1.628a1.75 1.75 0 011.734 1.51L5.18 3a65.25 65.25 0 0113.36 1.412.75.75 0 01.58.875 48.645 48.645 0 01-1.618 6.2.75.75 0 01-.712.513H6a2.503 2.503 0 00-2.292 1.5H17.25a.75.75 0 010 1.5H2.76a.75.75 0 01-.748-.807 4.002 4.002 0 012.716-3.486L3.626 2.716a.25.25 0 00-.248-.216H1.75A.75.75 0 011 1.75zM6 17.5a1.5 1.5 0 11-3 0 1.5 1.5 0 013 0zM15.5 19a1.5 1.5 0 100-3 1.5 1.5 0 000 3z" />
            </svg>
        )},
        { title: 'Leads', key: '/leads', route: '/leads', icon: (
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="w-5 h-5">
                <path fillRule="evenodd" d="M13.5 4.938a7 7 0 1 1-9.006 1.737c.202-.257.59-.218.793.039.278.352.594.672.943.954.332.269.786-.049.773-.476a5.977 5.977 0 0 1 .572-2.759 6.026 6.026 0 0 1 2.486-2.665c.247-.14.55-.016.677.238A6.967 6.967 0 0 0 13.5 4.938ZM14 12a4 4 0 0 1-4 4c-1.913 0-3.52-1.398-3.91-3.182-.093-.429.44-.643.814-.413a4.043 4.043 0 0 0 1.601.564c.303.038.531-.24.51-.544a5.975 5.975 0 0 1 1.315-4.192.447.447 0 0 1 .431-.16A4.001 4.001 0 0 1 14 12Z" clipRule="evenodd" />
            </svg>

        ) },
        { title: 'Sessões de Portaria', key: '/gatewaySessions', route: `/gatewaySessions`, icon: (
            <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                <path d="M5 12a1 1 0 102 0V6.414l1.293 1.293a1 1 0 001.414-1.414l-3-3a1 1 0 00-1.414 0l-3 3a1 1 0 001.414 1.414L5 6.414V12zM15 8a1 1 0 10-2 0v5.586l-1.293-1.293a1 1 0 00-1.414 1.414l3 3a1 1 0 001.414 0l3-3a1 1 0 00-1.414-1.414L15 13.586V8z" />
            </svg>
        )},
        /*
        { title: 'Sessões de Portaria', key: '/gatewaySessions', route: `/gatewaySessions`, icon: (
            <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                <path d="M5 12a1 1 0 102 0V6.414l1.293 1.293a1 1 0 001.414-1.414l-3-3a1 1 0 00-1.414 0l-3 3a1 1 0 001.414 1.414L5 6.414V12zM15 8a1 1 0 10-2 0v5.586l-1.293-1.293a1 1 0 00-1.414 1.414l3 3a1 1 0 001.414 0l3-3a1 1 0 00-1.414-1.414L15 13.586V8z" />
            </svg>
        )},
        { title: 'Landing Pages', key: '/landing-pages', route: `/landing-pages`, icon: <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
  <path fillRule="evenodd" d="M4.083 9h1.946c.089-1.546.383-2.97.837-4.118A6.004 6.004 0 004.083 9zM10 2a8 8 0 100 16 8 8 0 000-16zm0 2c-.076 0-.232.032-.465.262-.238.234-.497.623-.737 1.182-.389.907-.673 2.142-.766 3.556h3.936c-.093-1.414-.377-2.649-.766-3.556-.24-.56-.5-.948-.737-1.182C10.232 4.032 10.076 4 10 4zm3.971 5c-.089-1.546-.383-2.97-.837-4.118A6.004 6.004 0 0115.917 9h-1.946zm-2.003 2H8.032c.093 1.414.377 2.649.766 3.556.24.56.5.948.737 1.182.233.23.389.262.465.262.076 0 .232-.032.465-.262.238-.234.498-.623.737-1.182.389-.907.673-2.142.766-3.556zm1.166 4.118c.454-1.147.748-2.572.837-4.118h1.946a6.004 6.004 0 01-2.783 4.118zm-6.268 0C6.412 13.97 6.118 12.546 6.03 11H4.083a6.004 6.004 0 002.783 4.118z" clipRule="evenodd" />
</svg> },
*/
    ], [noSearchQueryString])

    return (
        <>
            <Head>
                <title>Producer - Byma</title>
            </Head>
            <div className={`bg-white transition-opacity duration-100 fixed inset-0 overflow-auto ${menuOpen ? 'opacity-100 z-50' : 'opacity-0 -z-1'} p-3 flex flex-col justify-between`}>
                <div>
                    <div className="flex justify-between items-center mb-6">
                        <button className="focus:bg-gray-200" onClick={() => setMenuOpen(false)}>
                            <FiX size={24}/>
                        </button>
                        <a href="/" alt="Byma">
                            <Logo className="w-16 h-5 fill-primary" />
                        </a>
                        <div className="w-16">
                        </div>
                    </div>
                    <hr className="my-4" />
                    <ul>
                        {menuItems.map(item => <MenuItem active={getFirstRoute(pathname) === item.key} {...{item}} />)}
                    </ul>
                </div>
                <div>
                    <ul>
                        {bottomMenuItems.map(item => <BottomMenuItem active={getFirstRoute(pathname) === item.key} {...{item}} />)}
                    </ul>
                    <button className={`flex items-center rounded-full cursor-pointer hover:font-bold hover:text-primary hover:bg-red-100 px-4 py-2 mb-3 text-sm focus:bg-red-200 w-full`} onClick={logout}>
                        <FiLogOut />
                        <p className="ml-2">
                            Sair
                        </p>
                    </button>
                </div>
            </div>
            <div className="h-screen flex flex-col md:flex-row">
                <div className="hidden md:block p-6 border-r w-64">
                    <div className="flex flex-col justify-between h-full">
                        <div>
                            <a href="/" alt="Byma" className="mb-6">
                                <Logo className="w-16 h-5 fill-primary" />
                            </a>
                            <hr className="my-4" />
                            <ul>
                                {menuItems.map(item => <MenuItem active={getFirstRoute(pathname) === item.key} {...{item}} />)}
                            </ul>
                        </div>
                        <div>
                            <ul>
                                {bottomMenuItems.map(item => <BottomMenuItem active={getFirstRoute(pathname) === item.key} {...{item}} />)}
                            </ul>
                            <button className={`flex items-center rounded-full cursor-pointer hover:font-bold hover:text-primary hover:bg-red-100 px-4 py-2 mb-3 text-sm focus:bg-red-200 w-full`} onClick={logout}>
                                <FiLogOut />
                                <p className="ml-2">
                                    Sair
                                </p>
                            </button>
                        </div>
                    </div>
                </div>
                <div className="flex-1 flex overflow-hidden">
                    <div className="flex-1 overflow-y-auto">
                        <div className="flex sticky top-0 left-0 right-0 justify-between items-center md:hidden p-3 shadow bg-white z-40">
                            <button className="focus:bg-gray-200 rounded-lg" onClick={() => setMenuOpen(true)}>
                                <FiMenu size={24} />
                            </button>
                            <div className="w-full items-center justify-center flex">
                                <a href="/" alt="Byma">
                                    <Logo className="w-16 h-5 fill-primary" />
                                </a>
                            </div>
                            <div className="w-16">
                                &nbsp;
                            </div>
                        </div>
                        <div className="h-full">
                            {children}
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
