export default function Logo({className}) {
    return (
        <svg version="1.2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 2050 513" {...{className}}>
                <path id="Path1" className="s0" d="m665.1 302.5l-61.8-201.9h-180.7l121.2 311.1h73.2c-4.5 18.3-13.7 27.5-38.9 27.5h-38.8v73.2h64c100.7 0 132.7-32.1 160.1-109.8l107.5-302h-144.1z"/>
                <path id="Path2" className="s0" d="m1475.8 98.3h-54.9c-58.3 0-88.1 26.3-103.5 60.1-13.2-32.6-44.6-60.1-100.1-60.1h-57.2c-55.4 0-77.7 27.5-91.5 59.5v-57.2h-176.1v311.1h176.1v-187.6c0-27.4 16-41.1 38.9-41.1h9.2c22.8 0 38.9 13.7 38.9 41.1v187.6h176.1v-187.6c0-27.4 16-41.1 38.9-41.1h9.1c22.9 0 38.9 13.7 38.9 41.1v187.6h176.2v-205.9c0-61.7-41.2-107.5-119-107.5z"/>
                <path id="Compound" fillRule="evenodd" className="s0" d="m2050 224.1v187.6h-176.1v-56.6c-15.5 34.9-46.4 58.9-114.4 58.9h-34.3c-82.4 0-116.7-36.6-116.7-86.9 0-64.1 50.3-84.7 146.4-92.7l80.1-6.8c28.6-2.3 36.6-9.2 36.6-22.9 0-13.7-9.2-21.7-36.6-21.7h-16.1c-28.5 0-36.6 12.6-38.8 32h-162.5v-4.6c0-66.3 52.7-112.1 162.5-112.1h105.2c112.1 0 164.7 48.1 164.7 125.8zm-176.1 36.6c-9.2 5.2-20.1 8.1-37.8 10.3l-18.3 2.3c-26.3 3.5-35.5 13.2-35.5 29.2 0 15.4 10.9 26.9 36.6 26.9h11.5c29.7 0 43.5-16 43.5-45.8z"/>
                <path id="Path3" className="s0" d="m327.5 97.8v-0.1h-1.5q-4.3-0.2-8.9-0.2h-42.9c-3.2 0-6.2 0.1-9.1 0.2h-90.3v-97.3h-174v179.9l212.4 0.8h11.3c22.6 0 38.4 13.5 38.4 40.7v63.2c0 27.1-15.8 40.7-38.4 40.7h-11.3c-22.6 0-38.4-13.6-38.4-40.7v-72.4h-174v194.4h140.1l128.8 2.3h47.4c79.1 0 119.8-45.2 119.8-110.7v-90.4c0-62.6-37.2-106.6-109.4-110.4z"/>
        </svg>
    )
}
