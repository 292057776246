import React from 'react'
import dayjs from 'dayjs'
import usePrevious from '@byma/shared/hooks/usePrevious'

function toQueryString(obj, forceKey=null) {
    if (!obj) return ''
    return Object.keys(obj).map(function (key) {
        if (typeof obj[key] === 'object') {
            return toQueryString(obj[key], key)
        }
        return (forceKey || key) + '=' + obj[key]
    }).join('&');
}

let dateFilters = {
    '1d': {
        fromDate: dayjs().startOf('day').toISOString(),
        toDate: dayjs().endOf('day').toISOString(),
    },
    '7d': {
        fromDate: dayjs().subtract(6, 'days').startOf('day').toISOString(),
        toDate: dayjs().endOf('day').toISOString(),
    },
    '30d': {
        fromDate: dayjs().subtract(29, 'days').startOf('day').toISOString(),
        toDate: dayjs().endOf('day').toISOString(),
    },
    '60d': {
        fromDate: dayjs().subtract(59, 'days').startOf('day').toISOString(),
        toDate: dayjs().endOf('day').toISOString(),
    },
    'all': {},
}

export default function useFilters(query, onChange, options = {}) {
    const { defaultDateFilter = '7d' } = options
    const [filters, setFilters] = React.useState({ parsed: {}, raw: {}});
    const { q = null, c = null, cp = null, p = null, st = null, d = defaultDateFilter, td = null, fd = null } = query

    const previousValues = usePrevious({ c, cp, p, d, st, q, td, fd, })

    React.useEffect(() => {
        if (
            !previousValues || (
                previousValues.c !== c ||
                previousValues.cp !== cp ||
                previousValues.p !== p ||
                previousValues.d !== d ||
                previousValues.fd !== fd ||
                previousValues.td !== td ||
                previousValues.st !== st ||
                previousValues.q !== q 
            )
        ) {
            let filterParams = {}

            if (d === 'cd') {
                filterParams = {
                    ...filterParams,
                    fromDate: fd,
                    toDate: td,
                }
            } else if (dateFilters[d]) {
                filterParams = {
                    ...filterParams,
                    ...dateFilters[d],
                }
            } else {
                filterParams = {
                    ...filterParams,
                    ...dateFilters[defaultDateFilter],
                }
            }

            if (q) {
                filterParams.search = q
            }

            if (st) {
                filterParams.status = st
            }

            if (p) {
                filterParams.partyIds = p
            }

            if (c) {
                filterParams.created_by = c
            }

            if (cp) {
                filterParams.coupons = cp
            }
            setFilters({ parsed: filterParams, raw: { c, cp, d, p, st, q, td, fd, }})
        }
    }, [query])

    React.useEffect(() => {
        onChange && onChange(filters)
    }, [filters])

    return {
        noSearchQueryString: toQueryString(Object.fromEntries(Object.entries({ c, cp, d, fd, td, p, st }).filter(([_, v]) => v != null))),
        originalQueryString: toQueryString(Object.fromEntries(Object.entries({ c, cp, d, fd, td, p, st, q }).filter(([_, v]) => v != null))),
        queryString: toQueryString(filters.parsed),
        rawFilters: {
            dateKey: filters.raw.d,
            customFromDate: filters.raw.fd,
            customToDate: filters.raw.td,
            parties: filters.raw.p,
            channels: filters.raw.c,
            coupons: filters.raw.cp,
            status: filters.raw.st,
            search: filters.raw.q,
        },
        parsedFilters: filters.parsed,
    }
}
