export default async function fetcher(route, options = {}, token = null) {
    if (!route) {
        return null
    }
    let headers = {
        'Content-Type': 'application/json',
    }
    if (token) {
        headers.Auth = token
    }
    const response = await fetch(route, {
        ...options,
        headers
    })
    const json = await response.json()
    if (!response.ok) {
        const error = new Error('An error occurred while fetching the data.')
        error.info = json
        error.status = response.status
        if ((response.status === 401 || response.status === 403) && typeof window !== 'undefined') {
            window.location.href = process.env.NEXT_PUBLIC_AUTH_APP_URL
        }
        throw error
    }
    return json
}

